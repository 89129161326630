import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { getAccountBalance } from '../../contracts/api';

export default ({ onChange }) => {
    const [payAmount, setPayAmount] = useState("");
    const [balance, setBalance] = useState(0);
    const { address } = useAccount();

    const onPayAmount = (e) => {
        const _amount = e.target.value;

        if (isNaN(_amount)) {
            toast.error('Please enter a valid number');
            return;
        }

        setPayAmount(_amount);
        onChange(_amount);
    }

    const onMax = () => {
        setPayAmount((balance * 1000).toFixed(0) / 1000);
    }

    useEffect(() => {
        if (!address) return;

        getAccountBalance(address).then((res) => {
            setBalance(res);
        })
    }, [address]);

    return (
        <Box className="purchase-wrapper">
            <Box className="input-group">
                <div>
                    <Typography className='fs-18'>Amount</Typography>
                    <input type="text" placeholder="0.00" value={payAmount} onChange={onPayAmount} />
                </div>
                <Button variant="contained" className='btn-max' onClick={onMax}>MAX</Button>
                <Box className="flex items-center menu-item">
                    <img src="/assets/images/bnb.png" alt="bnb" />
                    <Typography className='coin-name'>BNB</Typography>
                </Box>
            </Box>
        </Box>
    )
}
