import { useEffect, useState } from 'react';
import ABI from './abi.json';
import Web3 from 'web3';
import { writeContract } from '@wagmi/core';
import { showError } from '../utils';

const CONTRACT_ADDRESS = '0x2437A3FCB9d6319dE90e29B2814a4F0F1E0cDDB9';

const web3 = new Web3("wss://bsc-rpc.publicnode.com	");

export const getAccountBalance = async (address) => {
    try {
        const balanceWei = await web3.eth.getBalance(address);
        const balanceBnb = web3.utils.fromWei(balanceWei, 'ether');

        return balanceBnb;
    } catch (error) {
        console.error('Error getting account balance:', error);
        throw error;
    }
};

export const getBNBRaised = async () => {
    const bnbRaised = await getAccountBalance(CONTRACT_ADDRESS);

    return bnbRaised;
};

export const buyTokenByBNB = async (address, amount) => {
    try {
        const weiValue = web3.utils.toWei(amount.toString());

        await writeContract({
            address: CONTRACT_ADDRESS,
            abi: ABI,
            functionName: 'deposit',
            value: weiValue,
            account: address,
        });
    } catch (res) {
        console.log("buyToken Error: ", res.error || res);
        showError(res.error || res);
    }
}